function CompGroup(props) {
  return (
    <div className="comp-group">
      <img src={props.src} alt="img" />
      <h5>{props.title}</h5>
    </div>
  );
}

export default CompGroup;
