import CompTags from "./CompTags";
function index() {
  return (
    <div className="popular">
      <h3>Popular tags</h3>
      <CompTags />
      <CompTags />
      <CompTags />
    </div>
  );
}

export default index;
