import Main from "../components/Home/main/";
import Sideleft from "../components/Home/sideleft/";
import Sideright from "../components/Home/sideright/";
import Post from "../components/Home/post/";
const Home = () => {
  return (
    <>
      <div className="home">
        <Post />
        <Sideleft />
        <Main />
        <Sideright />
      </div>
    </>
  );
};

export default Home;
