import Login from "./CompLogin";
function index() {
  return (
    <div>
      <Login />
    </div>
  );
}

export default index;
