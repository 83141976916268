import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { FaCalendarAlt, FaBell, FaUserCircle } from "react-icons/fa";
import { TiGroup } from "react-icons/ti";
import { ImHeadphones } from "react-icons/im";
import { GiMicrophone } from "react-icons/gi";
import { RiSearchLine } from "react-icons/ri";

const Navbar = () => {
  const pathName = useLocation().pathname;
  function cekTitle(path) {
    if (width) {
      if (path === "/") {
        return "home";
      } else if (path === "/meetup") {
        return "meetup";
      } else if (path === "/group") {
        return "group";
      } else if (path === "/podcast") {
        return "podcast";
      } else if (path === "/vn") {
        return "voice";
      } else if (path === "/profile") {
        return "profile";
      }
    } else {
      return <Link to="/">Namanya Juga Hidup</Link>;
    }
  }
  const [width, setwidth] = useState(false);
  useEffect(() => {
    if (document.body.clientWidth < 769) {
      setwidth(true);
    }
    var query = window.matchMedia("(max-width: 768px)");
    query.addEventListener("change", () => {
      if (query.matches) {
        setwidth(true);
      } else {
        setwidth(false);
      }
    });
  }, []);
  return (
    <>
      <nav>
        <div className="container">
          <div className="logo">
            <h2>{cekTitle(pathName)}</h2>
          </div>
          <div className="main-icons">
            <span className={pathName === "/" ? "active" : ""}>
              <Link to="/">
                <AiFillHome className="icon " />
              </Link>
            </span>
            <span className={pathName === "/meetup" ? "active" : ""}>
              <Link to="/meetup">
                <FaCalendarAlt className="icon" />
              </Link>
            </span>
            <span className={pathName === "/group" ? "active" : ""}>
              <Link to="/group">
                <TiGroup className="icon" />
              </Link>
            </span>
            <span className={pathName === "/podcast" ? "active" : ""}>
              <Link to="/podcast">
                <ImHeadphones className="icon" />
              </Link>
            </span>
            <span className={pathName === "/vn" ? "active" : ""}>
              <Link to="/vn">
                <GiMicrophone className="icon" />
              </Link>
            </span>
          </div>
          <div className="search-bar">
            <input
              type="search"
              id="search"
              autoComplete="off"
              placeholder="search.."
            />
            <label htmlFor="search">
              <RiSearchLine className="icon" />
            </label>
          </div>
          <div className="profile">
            {/* <AiFillMessage className="icon" /> */}
            <FaBell className="icon" />
            <Link to="profile">
              <FaUserCircle className="icon" />
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
