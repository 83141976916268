import Meetup from "./meetup/";
import Podcast from "./podcast/";
function index() {
  return (
    <div className="sideright">
      <Meetup />
      <Podcast />
    </div>
  );
}

export default index;
