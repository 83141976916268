import CompMeetup from "./CompMeetup";
function index() {
  return (
    <div className="meetup">
      <h3>Meetups</h3>
      <CompMeetup />
      <CompMeetup />
      <CompMeetup />
    </div>
  );
}

export default index;
