import { Link } from "react-router-dom";
import { AiOutlineGoogle } from "react-icons/ai";
import { MdFacebook } from "react-icons/md";
function CompLogin() {
  return (
    <>
      <div className="comp-login">
        <h2>Sign In</h2>
        <div className="login-with">
          <button>
            <AiOutlineGoogle className="log" />
            Continue with Google
          </button>
          <button>
            <MdFacebook className="log" />
            Continue with Facebook
          </button>
        </div>
        <span>or</span>
        <form action="/">
          <input
            type="email"
            name="email"
            placeholder="E-mail"
            autoComplete="off"
          />
          <input
            type="password"
            name="password"
            placeholder="Password"
            autoComplete="off"
          />
          <button>Sign In</button>
        </form>
        <span className="create-acc">
          don't have account?
          <Link to="/register" className="link">
            Create Account
          </Link>
        </span>
        <Link to="/register" className="link">
          Forgot password?
        </Link>
      </div>
    </>
  );
}

export default CompLogin;
