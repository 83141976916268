import { MdFiberNew } from "react-icons/md";
import { GiStarsStack } from "react-icons/gi";
import { RiUserSharedFill } from "react-icons/ri";
import CompSidebar from "./CompSidebar";
function index() {
  return (
    <>
      <div className="sidebar">
        <CompSidebar
          icon={<MdFiberNew className="icon" />}
          title={"Newest and Recent"}
          subtitle={"Find the last update"}
        />
        <CompSidebar
          icon={<GiStarsStack className="icon" />}
          title={"Popular of the day"}
          subtitle={"Shots featured today by curators"}
        />
        <CompSidebar
          icon={<RiUserSharedFill className="icon" />}
          title={"Following"}
          subtitle={"Explore from your favorite person"}
        />
      </div>
    </>
  );
}

export default index;
