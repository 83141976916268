import Coinbase from "../../../../assets/img/coinbase.png";
import Metamask from "../../../../assets/img/metamask.png";
import Walletcoin from "../../../../assets/img/wallet.png";
import CompGroup from "./CompGroup";
function index() {
  return (
    <div className="group">
      <h3>Pinned Group</h3>
      <CompGroup src={Coinbase} title={"Social Networking"} />
      <CompGroup src={Metamask} title={"React Js Mastery"} />
      <CompGroup src={Walletcoin} title={"Laravel Indonesia"} />
    </div>
  );
}

export default index;
