import { Link, useLocation } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { FaCalendarAlt } from "react-icons/fa";
import { TiGroup } from "react-icons/ti";
import { ImHeadphones } from "react-icons/im";
import { GiMicrophone } from "react-icons/gi";
const Footer = () => {
  const pathName = useLocation().pathname;
  return (
    <>
      <footer>
        <div className="container">
          <span className={pathName === "/" ? "active" : ""}>
            <Link to="/">
              <AiFillHome className="icon " />
            </Link>
          </span>
          <span className={pathName === "/meetup" ? "active" : ""}>
            <Link to="/meetup">
              <FaCalendarAlt className="icon" />
            </Link>
          </span>
          <span className={pathName === "/group" ? "active" : ""}>
            <Link to="/group">
              <TiGroup className="icon" />
            </Link>
          </span>
          <span className={pathName === "/podcast" ? "active" : ""}>
            <Link to="/podcast">
              <ImHeadphones className="icon" />
            </Link>
          </span>
          <span className={pathName === "/vn" ? "active" : ""}>
            <Link to="/vn">
              <GiMicrophone className="icon" />
            </Link>
          </span>
        </div>
      </footer>
    </>
  );
};

export default Footer;
