import { FaUserCircle } from "react-icons/fa";
function CompPost() {
  return (
    <div className="post-card">
      <div className="comp-post">
        <div
          className="close"
          onClick={() => {
            document.querySelector(".post").classList.remove("active");
          }}
        ></div>
        <h4>Buat Postingan</h4>
        <hr />

        <form action="">
          <div className="user">
            <FaUserCircle />
            <h5>Mikirin Apa?</h5>
          </div>
          <textarea></textarea>
          <button>Kirim</button>
        </form>
      </div>
    </div>
  );
}

export default CompPost;
