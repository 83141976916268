import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Layout from "./pages/Layout";
import Meetup from "./pages/Meetup";
import Group from "./pages/Group";
import Profile from "./pages/Profile";
import Podcast from "./pages/Podcast";
import Voice from "./pages/Voice";
import Login from "./pages/Login";
import "./assets/style.scss";
const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="login" element={<Login />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="profile" element={<Profile />} />
            <Route path="meetup" element={<Meetup />} />
            <Route path="group" element={<Group />} />
            <Route path="podcast" element={<Podcast />} />
            <Route path="vn" element={<Voice />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
};
export default App;
