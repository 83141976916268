import CompLogin from "../components/Login/";
function Login() {
  return (
    <div className="login">
      <CompLogin />
    </div>
  );
}

export default Login;
