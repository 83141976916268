import Coinbase from "../../../../assets/img/coinbase.png";
import Metamask from "../../../../assets/img/metamask.png";
import Walletcoin from "../../../../assets/img/wallet.png";
import CompPodcast from "./CompPodcast";
function index() {
  return (
    <div className="podcast">
      <h3>Podcast</h3>
      <CompPodcast src={Coinbase} title={"Baby"} artist={"by Clean Bandit"} />
      <CompPodcast src={Metamask} title={"Ghost"} artist={"by Justin Bieber"} />
      <CompPodcast
        src={Walletcoin}
        title={"Night Changes"}
        artist={"by One Direction"}
      />
    </div>
  );
}

export default index;
