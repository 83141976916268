function CompPodcast(props) {
  return (
    <div className="comp-podcast">
      <img src={props.src} alt="podcast" />
      <div className="comp-podcast-text">
        <h5>{props.title}</h5>
        <span>{props.artist}</span>
      </div>
    </div>
  );
}

export default CompPodcast;
