function CompSidebar(props) {
  return (
    <div className="comp-sidebar">
      {props.icon}
      <div className="comp-sidebar-text">
        <h3>{props.title}</h3>
        <span>{props.subtitle}</span>
      </div>
    </div>
  );
}

export default CompSidebar;
