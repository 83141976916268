import { FaUserCircle } from "react-icons/fa";
function CompPost() {
  return (
    <>
      <div className="comp-post">
        <FaUserCircle className="icon" />
        <button
          onClick={() => {
            document.querySelector(".post").classList.add("active");
          }}
        >
          Let's share what's in your mind?
        </button>
      </div>
    </>
  );
}

export default CompPost;
