import Sidebar from "./sidebar";
import Popular from "./popular";
import Group from "./group";
function index() {
  return (
    <div className="sideleft">
      <Sidebar />
      <Popular />
      <Group />
    </div>
  );
}

export default index;
