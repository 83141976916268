function CompMeetup() {
  return (
    <div className="comp-meetup">
      <div className="left">
        <h3>FEB</h3>
        <h4>7</h4>
      </div>
      <div className="right">
        <h5>Dessign meetups USA | Dribble</h5>
        <span>Dribble - Austin, Texas, USA</span>
      </div>
    </div>
  );
}

export default CompMeetup;
