import CompPost from "./CompPost";
function index() {
  return (
    <div className="post">
      <CompPost />
    </div>
  );
}

export default index;
