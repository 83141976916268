function CompTags() {
  return (
    <div className="comp-tags">
      <h5>#Tags1</h5>
      <span>1.8k</span>
    </div>
  );
}

export default CompTags;
